import React from 'react'
import Fade from 'react-reveal/Fade';
import './HomeRemodeling.css'
import RemodelingSection from '../../RemodelingSection';
import { homeObjOne, homeObjThree, homeObjTwo, homeObjFour,} from './Data'
import { Helmet } from 'react-helmet';


function HomeRemodeling() {
    return (
        <>
        <Helmet>
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
      <title>RESIDENTIAL & COMMERCIAL REMODEL | Houston Texas Home Remodeling 832-857-5200</title>
      <meta
      name="description"
      content=" Call us today 832-857-5200. Home Remodeling, Roof Installation in Houston Texas, Residential and Commercial Services.
      "
    />
    <meta 
    name="keywords"
    content="Houston Texas, Home Remodel, Kitchen remodeling, Bathroom remodeling or Commercial remodel 832-857-5200 "
    />
    </Helmet>
         <div className="remodel_hero">
             <Fade right>
             <h1><span>RESIDENTIAL & COMMERCIAL REMODEL</span></h1>
             </Fade>
         </div>
         <Fade right>
         <RemodelingSection {...homeObjOne} />
         </Fade>
         <Fade right>
         <RemodelingSection {...homeObjThree} />
         </Fade>
         <Fade bottom>
         <RemodelingSection {...homeObjTwo} />
         </Fade>
         <Fade left>
         <RemodelingSection {...homeObjFour} />
         </Fade>
         {/* <div className="remodel_section">
             <Fade top>
             <h2>Areas We Work</h2>
             </Fade>
             <Fade left>
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
             </Fade>
         </div> */}
        </>
    )
}

export default HomeRemodeling;
