
export const homeObjOne = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: 'OUR WORK',
    Headline: 'RESIDENTIAL & COMMERCIAL PAINTING SERVICES',
    description: 'At JB & C Home Solutions we will provide you with the best interior & exterior painting solutions for your home or business.',
    description1: 'Some of our Interior and Exterior Services include: Painting, Carpentry, Sheetrock, Cabinets, Doors, Walls, Powerwash and more.  ',
    buttonLabel: '',
    imgStart: '',
    img: './images/homeremodel5.jpeg',
    alt: '',
    start:'true'
};
export const homeObjThree = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'INTERIOR PAINTING',
    description: 'If your Home or Business is in need of Interior Painting or Repair, we are the right choice for you! We will help you choose the right color for your Home or Business. We use top of the notch materials from Sherwin-Williams and other big brand names.',
    description1:'For more information make sure to give us a call for a free quote',
    phone:'SCHEDULE A CALL',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/painting1.jpeg',
    alt: 'Credit Card',
    start: 'true'
};
export const homeObjTwo = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'EXTERIOR PAINTING',
    description: 'If your Home or Business is in need of Exterior Painting or Repair, we are the right choice for you! Over the past 10 years we have done Painting Contract work all over the Houston area ensuring we give top quality exterior paint job to all of our customers. ',
    phone:'SCHEDULE A CALL',
    buttonLabel: '',
    imgStart: '',
    img: '/images/2.jpg',
    alt: 'Credit Card'
};
export const homeObjFour= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'COMMMERCIAL GUTTER INSTALLATION',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/3.jpeg',
    alt: 'Credit Card'
};
export const homeObjFive= {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'Commercial Remodel',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/homeremodel1.jpeg',
    alt: 'Credit Card'
};

export const homeObjSix= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    Headline: 'Areas We Work',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    img: ''
}

