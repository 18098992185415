
export const homeObjOne = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: 'OUR WORK',
    Headline: 'GUTTER REPAIR & CLEANING IN HOUSTON',
    description: "Cleaning out a gutter is a chore that no one wants to do, not only it can be time consuming but it can also be dangerous. Our team at JB & C Home Solutions will get the job done safely and efficient. We will make sure to let you know if you have missing or damaged shingles.",
    phone: 'Call us Today',
    buttonLabel: '',
    imgStart: '',
    img: './images/3.jpg',
    alt: '',
    start:'true'
};
export const homeObjThree = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'RESIDENTIAL GUTTER',
    description: 'We have numerous types of gutters available to accommodate to any home in the Houston and San Antonio area. It is very important for your house to have working gutters to prevent erosion, mold and mildew growth. If you feel like your Gutters are not working the way they supposed to give us a call for a free quote.',
    phone: 'Call us Today',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/8-22.jpg',
    alt: 'Credit Card',
    start: 'true'
};
export const homeObjTwo = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'COMMERCIAL GUTTER REPAIR',
    description: 'Does your business need Gutter work done? We are the right choice for you! Make sure to give us a call for a Free estimate',
    buttonLabel: '',
    imgStart: '',
    img: '/images/roofing2.jpeg',
    alt: 'Credit Card'
};
export const homeObjFour= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'COMMMERCIAL GUTTER INSTALLATION',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/3.jpeg',
    alt: 'Credit Card'
};
export const homeObjFive= {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'Commercial Remodel',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/homeremodel1.jpeg',
    alt: 'Credit Card'
};

export const homeObjSix= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    Headline: 'Areas We Work',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    img: ''
}

