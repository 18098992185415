import React from 'react'
import './grid-customer.css'
import Fade from 'react-reveal/Fade'
import Counter from './Counter'
import {Link} from 'react-router-dom';
import { Button } from './Button'

export default function OurCustomer() {
    return (
        <div className="main1">
          <Fade right>
            <h3>Schedule Your Service Today</h3>
            </Fade>
          <Fade left>
            <p className="p1">With 10+ years of experience in the field we will make your service as stress-free as possible, Our Job is to give you the best and affordable experience you can have. <br/>
            <Link to='/contactform'>
                 <Button buttonSize='btn--large' buttonColor='primary' buttonStyle='btn--primary'>Contact Us</Button>
            </Link> </p>
            </Fade>
            <Counter/>
            <Fade left>
            <h2>OUR CUSTOMERS</h2>
            </Fade>
            <Fade right> 
            <div className="gallery1">
          <a href="https://www.memphisinvestmentproperties.net/">
            <img className="grid1" src="/images/mip.png" alt="alt" />
          </a>
          <a href="https://houstonelectricalservice.com/">
            <img className="grid1" src="/images/int.jpg" alt="alt"/>
          </a>
          {/* <a href="//">
            <img className="grid1" src="/images/logo2.png" alt="alt"/>
          </a>
          <a href="//">
            <img className="grid1" src="/images/logo2.png" alt="alt"/>
          </a>
          <a href="//">
            <img className="grid1" src="/images/logo2.png" alt="alt"/>
          </a>
          <a href="//">
            <img className="grid1" src="/images/logo2.png" alt="alt"/>
          </a> */}
          
        </div>
        </Fade>
        </div>
    )
}
