import React from 'react'
import './Concrete.css'
import RemodelingSection from '../../RemodelingSection'
import {homeObjOne, homeObjThree,homeObjTwo} from './Data'
import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'

function Concrete() {
    return (
        <>
         <div className="concrete_hero">
             <Fade right>
             <h1><span> Concrete Services In Houston</span></h1>
             </Fade>
         </div>
         <Fade left>
         <RemodelingSection {...homeObjOne} />
         </Fade>
         <Bounce>
         <RemodelingSection {...homeObjThree} />
         </Bounce>
         {/* <Fade right>
         <RemodelingSection {...homeObjTwo} />
         </Fade> */}
         {/* <div className="concrete_section">
             <Fade top>
             <h2>Gutter Services Include</h2>
             </Fade>
             <Fade left>
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
             </Fade>
             <li>K-Style</li>
             <li>Half-Round</li>
             <li>Box gutters</li>
             <li>lorem</li>
         </div> */}
        </>
    )
}



export default Concrete
