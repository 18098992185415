import React from 'react'
import './Counter.css'



function Counter() {
    const counters = document.querySelectorAll('.counter');
const speed = 200; // The lower the slower

counters.forEach(counter => {
	const updateCount = () => {
		const target = +counter.getAttribute('data-target');
		const count = +counter.innerText;

		// Lower inc to slow and higher to slow
		const inc = target / speed;

		// console.log(inc);
		// console.log(count);

		// Check if target is reached
		if (count < target) {
			// Add inc to count and output in counter
			counter.innerText = count + inc;
			// Call function every ms
			setTimeout(updateCount, );
		} else {
			counter.innerText = target;
		}
	};

	updateCount();
});
    return (
        
        <>
          
		<section class="counters">
			<div class="container">
				<div>
					<i class="fab fa-youtube fa-4x"></i>
					<div class="counter" data-target="1000">1000 +</div>
					<h3>COSTUMERS SERVED</h3>
				</div>
				<div>
					<i class="fab fa-twitter fa-4x"></i>
					<div class="counter" data-target="4000">4000 +</div>
					<h3>HOURS IN THE FIELD</h3>
				</div>
				<div>
					<i class="fab fa-linkedin fa-4x"></i>
					<div class="counter" data-target="1000">1000 +</div>
					<h3>RELATIONSHIPS BUILD</h3>
				</div>
			</div>
		</section> 
        </>
    )
}

export default Counter
