import React from 'react'
import './RemodelingSection.css';

function RemodelingSection({
    lightBg, topLine, lightText, lightTextDesc, Headline, description, description1, phone,
    buttonLabel, img, alt, imgStart, video, 
}) {
    return (
   <> 
   <div className="hero__container"> 
       {/* <div className="hero__bg">
       <video playsInline loop autoPlay muted autoBuffer src={ video } className="video__bg" /> 
       </div> */}
    </div>
    <div className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
        <div className="container">
            <div className="row home__hero-row"
            style={{display:'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
                <div className="col">
                    <div className="home__hero-text-wrapper">
                        <div className="top-line">{topLine}</div>
                        <h1 className={lightText ? 'heading' : 'heading dark'}> {Headline} </h1>
                        <p className={lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}> {description} </p>
                        <p className={lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}> {description1} </p>
                        <a className={lightTextDesc ? 'home__hero-subtitle1' : 'home__hero-subtitle2 dark'} href="tel:832-857-5200"> {phone} </a>
                    </div>
                </div>
                <div className="home__hero-img-wrapper">
                    <img src={ img } alt="alt" className="home__hero-img"/>
                </div>
            </div>
        </div>
    </div>
   </>
    );
}

export default RemodelingSection
