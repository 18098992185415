import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import {BsFillLightningFill} from 'react-icons/bs';
import { IoAppsSharp} from "react-icons/io5";
import { IoCloseSharp } from 'react-icons/io5';
import { IoChevronDown } from 'react-icons/io5';
import './Navbar.css';
import {IconContext} from 'react-icons/lib';
import Dropdown from './Dropdown';
import mainLogo from '../../src/jbclogo.png';

function Navbar() {
    const[click, setClick] = useState(false);
    const [dropdown, setDropDown] = useState(false)
    const [button, setButton] = useState(true);
    // const [navbar, setNavbar] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false) ;

    const showButton = () => {
        if(window.innerWidth <= 1200) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    const onMouseEnter = () => {
        if (window.innerWidth < 1200) {
            setDropDown(true);
        } else {
            setDropDown(true);
        }
    };
    const onMouseLeave = () => {
        if (window.innerWidth < 1200) {
            setDropDown(false);
        } else {
            setDropDown(false);
        }
    };

    const extendElement = () => {
        dropdown? setDropDown(false):
        setDropDown(true);
    }
   

    useEffect(() => {
        showButton();
    }, []);

//    const changeBackground = () => {
//     if(window.scrollY >= 80) {
//         setNavbar(true)
//     } else {
//         setNavbar(false)
//     }
//    }

//    window.addEventListener('scroll', changeBackground);

    return (
        <>
        <IconContext.Provider value={{color: '#fff' }}>
            <div className='navbar active'>
                <div className="navbar-container container">
                    <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                        <img className="logo" src={mainLogo} alt="integrated electric" size="50" />
                        {/* <h1 className="logo">logo</h1> */}
                    </Link>
                    {/* <a className="phone1" href="tel:713-397-9247">713-397-9247 </a> */}
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <IoCloseSharp/> : <IoAppsSharp />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                HOME
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                ABOUT 
                            </Link>
                        </li>
                        <li className="nav-item"
                         onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={extendElement}
                        >
                            <Link to=''  className='nav-links'>
                            <IoChevronDown  />
                                SERVICES 
                            </Link>
                            {dropdown && <Dropdown onCloseMobileMenu={closeMobileMenu} />}
                        </li>
                        {/* <li className="nav-item">
                            <Link to='/contactform' className='nav-links' onClick={closeMobileMenu}>
                                CONTACT 
                            </Link>
                        </li> */}
                    
                    </ul>
                </div>
            </div>
            </IconContext.Provider>
        </>
    )
}

export default Navbar

