import React from 'react'
import './Gutters.css'
import RemodelingSection from '../../RemodelingSection'
import {homeObjOne, homeObjThree,homeObjTwo} from './Data'
import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'

function Gutters() {
    return (
        <>
         <div className="gutter_hero">
             <Fade right>
             <h1><span>RESIDENTIAL & COMMERCIAL GUTTER REPAIR & INSTALLATION</span></h1>
             </Fade>
         </div>
         <Fade left>
         <RemodelingSection {...homeObjOne} />
         </Fade>
         <Bounce>
         <RemodelingSection {...homeObjThree} />
         </Bounce>
         <Fade right>
         <RemodelingSection {...homeObjTwo} />
         </Fade>
         <div className="gutter_section">
             <Fade top>
             <h2>Gutter Services Include</h2>
             </Fade>
             <Fade left>
             <p>Gutter Cleaning, Gutter Repair and Gutter Installation. Some of the Gutter types we use are :</p>
             </Fade>
             <li>K-Style</li>
             <li>Half-Round</li>
             <li>Box gutters</li> 
             <li>Seamless gutters</li> 
         </div>
        </>
    )
}



export default Gutters
