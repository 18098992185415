
export const homeObjOne = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: 'OUR WORK',
    Headline: 'FLOORING REPAIR & INSTALLATION IN HOUSTON',
    description: "Looking to renovate or repair your home's floor? We are the right choice for you. Whether you're wanting new Tile, Granite Flooring, Laminate Flooring or Carpet replacement or repair." ,
    description1:'Schedule your appointment today for a free quote',
    phone:'GIVE US A CALL',
    buttonLabel: '',
    imgStart: '',
    img: './images/8.jpg',
    alt: '',
    start:'true'
};
export const homeObjThree = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'RESIDENTIAL',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    phone:'GIVE US A CALL',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/8-9.jpg',
    alt: 'Credit Card',
    start: 'true'
};
export const homeObjTwo = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'COMMERCIAL',
    description: 'Are you tired of seeing the same old carpet or tile in your business? Give us a call for a free estimate today!',
    phone:'GIVE US A CALL',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/flooring1.jpeg',
    alt: 'Credit Card',
    start:'true'
};
export const homeObjFour= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'COMMMERCIAL GUTTER INSTALLATION',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/3.jpeg',
    alt: 'Credit Card'
};
export const homeObjFive= {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'Commercial Remodel',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/homeremodel1.jpeg',
    alt: 'Credit Card'
};

export const homeObjSix= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    Headline: 'Areas We Work',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    img: ''
}

