import React from 'react'
import './Roofing.css'
import RemodelingSection from '../../RemodelingSection'
import {
    homeObjOne, 
    homeObjThree,
    homeObjTwo,
    homeObjFour,
    homeObjFive,
    homeObjSix} 
    from './Data'
import Fade from 'react-reveal/Fade'
import Bounce from 'react-reveal/Bounce'
function Roofing() {
    return (
        <>
         <div className="roofing_hero">
             <Fade right>
             <h1><span>RESIDENTIAL & COMMERCIAL ROOFING</span></h1>
             </Fade>
         </div>
         <Fade left>
         <RemodelingSection {...homeObjOne} />
         </Fade>
         <Bounce>
         <RemodelingSection {...homeObjThree} />
         </Bounce>
         <Fade right>
         <RemodelingSection {...homeObjTwo} />
         </Fade>
         <Fade left>
             <RemodelingSection {...homeObjFour} />
         </Fade>
         
         {/* <div className="roofing_section">
             <Fade top>
             <h2>Areas We Work</h2>
             </Fade>
             <Fade left>
             <li>HOUSTON</li>
             <li>KATY</li>
             <li></li>
             </Fade>
         </div> */}
        </>
    )
}

export default Roofing
