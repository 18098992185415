import React from 'react'
import Fade from 'react-reveal/Fade';
import './Home.css'
import HomeSection from '../../HomeSection';
import Gallery from '../../Gallery';
import OurCustomer from '../../OurCustomer';
import Counter from '../../Counter'
import {Helmet} from 'react-helmet';



function Home() {
    
    return (
        <>

<Helmet>
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
      <title>Home Remodel, Roofing & Seamless Gutters Installation | Houston Roofers 832-857-5200</title>
      <meta
      name="description"
      content=" Call us today 832-857-5200. Home Remodeling, Roof Installation in Houston Texas, Residential and Commercial Services.
      "
    />
    <meta 
    name="keywords"
    content="Houston, roofers, roofing, remodeling, painting, fence, Gutter Instalation, Residential, Commercial, repair, 832-857-5200 "
    />
    </Helmet>

        
            <div className="hero__image"> 
             <video playsInline loop autoPlay muted autoBuffer src="/videos/main1.mp4" className="video"/>
             <Fade right>
            <div className="remodel_hero1">
             <h1><span>The Best Seamless
                Gutters <br/> in Houston</span></h1>
                </div>
            </Fade>
             </div>
             <HomeSection/>
             <Gallery/>
             <OurCustomer/>
        </>
    )
}

export default Home;
