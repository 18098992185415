import React from 'react'
import './HomeSection.css'
import Fade from 'react-reveal/Fade';

function HomeSection() {
    return (
        <>
        <div className="home_section">
            <Fade right>
            <h1 className="section_header">AT JB&C HOME SOLUTIONS</h1>
            </Fade>
            <Fade top>
            <p className="section_paragraph"> We are here to provide you with a great experience. We take our job seriously and always look out for our costumers best interest, Finding what is best for them is our one priority. We take pride in what we do, with 10+ years of 
            experience in Roofing installation and repair, Home remodeling and Seamless Gutters. We provide expert inspections and estimates for your needs. </p>
            </Fade>
        <div className="gallery1">
            <Fade left>
            <a href="/roofing">
                <h3 className="h3">Roofing</h3>
            <img className="grid1" src="/images/1.jpg" alt="alt" />
            </a>
            </Fade>
            <Fade bottom>
            <a href="/homeremodeling">
            <h3 className="h3">Home Remodeling</h3>
            <img className="grid1" src="/images/2.jpg" alt="alt" />
            </a>
            </Fade>
            <Fade right>
            <a href="/seamlessgutters">
            <h3 className="h3">Seamless Gutters</h3>
            <img className="grid1" src="images/3.jpg" alt="alt" />
            </a>
            
            </Fade>
        </div>
            <Fade left>
            <h2 className="section_header1">Some of our services include</h2>
            <p className="section_paragraph"> Roofing, Home remodeling, Gutter Installation, Concrete work, Tile and Flooring, Painting, Housekeeping, Carpentry, and Fence work are some of the areas we mostly work on. All around the Houston area and San Antonio. </p>
            </Fade>
        </div>
        </>
    )
}

export default HomeSection
