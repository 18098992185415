
export const homeObjOne = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: 'OUR WORK',
    Headline: 'FENCE INSTALLATION IN HOUSTON',
    description: "One of JB & C Home Solution's specialties is Fence Installations for your Home or Business.",
    description1: 'Some of our Fence Services include: Wood Fencing,  Chain Link, Vinyl Fencing, Aluminum Fence  and more',
    phone: 'Call us Today',
    buttonLabel: '',
    imgStart: '',
    img: './images/8-18.jpg',
    alt: '',
    start:'true'
};
export const homeObjThree = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'LOREM IPSUM',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/homeremodel4.jpeg',
    alt: 'Credit Card',
    start: 'true'
};
export const homeObjTwo = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'lorem ipsum',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/2.jpg',
    alt: 'Credit Card'
};
export const homeObjFour= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'lorem ipsum',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/3.jpeg',
    alt: 'Credit Card'
};
export const homeObjFive= {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'Commercial Remodel',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/homeremodel1.jpeg',
    alt: 'Credit Card'
};

export const homeObjSix= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    Headline: 'Areas We Work',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    img: ''
}

