import emailjs from 'emailjs-com';
import React from 'react';
import './Contact.css';
import {IconContext} from 'react-icons/lib';
import {AiOutlineClockCircle } from 'react-icons/ai'
import{ init } from 'emailjs-com';
init("");


export default function Contact() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('', '', e.target, '')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
    }

    return(
        <IconContext.Provider value={{color: '#000', size: 34}}>
            <div className="container">
                <form onSubmit={sendEmail} className="form">
                <div class="title">CONTACT US</div>
                    <div className="input-container ic1">
                        
                        <div className="input-container ic2">
                            <input type="text" className="form-control" placeholder="Email Address" name="email" />
                        </div>
                        <div className="input-container ic2">
                            <input type="text" className="form-control" placeholder="Subject" name="subject" />
                        </div>
                        <div className="input-container ic2">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="How can we Help?" name="Your Message"></textarea>
                        </div>
                        <div className="col-8 pt-3 mx-auto">
                            <input type="submit" className="submit" value="Send Message"></input>
                        </div>
                    </div>
                </form>
            </div>
            {/* <div className="section">
            <div className="contact__info">
                <h2 className="opening__hours">OPENING HOURS</h2>
                <div className="icon1">
                <AiOutlineClockCircle />
                </div>
                <p>Monday - Friday : 8am - 8pm</p>
                <div className="icon1">
                <AiOutlineClockCircle />
                </div>
                <p>Saturday: By appointments</p>
                <div className="icon1">
                <AiOutlineClockCircle />
                </div>
                <p>Sunday: Closed</p>
            </div>
            <div className="contact__touch">
                <h2 className="connect">Get In Touch</h2>
                <p>9595 Six Pines, The Woodlands, TX 77380</p>
                <p>integrated-ec@consolidated.net</p>
                <p>713-397-9247</p>
            </div>
        </div>
        </div> */}
        </IconContext.Provider>
    )
}