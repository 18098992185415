
export const homeObjOne = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: 'OUR WORK',
    Headline: 'HOME REMODEL',
    description: "Here at JB & C Home Solutions we want to bring your dream alive whether is Kitchen remodeling, Bathroom remodeling or Commercial remodel we are here to help you get your job done quickly and efficient. Serving the Houston community for over 10 years we are the right company for your Home & Commercial Remodel. Schedule your appointment today!",
    buttonLabel: '',
    imgStart: '',
    img: './images/homeremodel2.jpeg',
    alt: '',
    start:'true'
};
export const homeObjThree = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'KITCHEN REMODEL',
    description: 'Make your kitchen look better than ever with a top of the line renovation. We will get the job done. Give us a call Today for a free estimate! ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/homeremodel1.jpeg',
    alt: 'Credit Card',
    start: 'true'
};
export const homeObjTwo = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'RESTROOM REMODEL',
    description: 'JB & C Home Solutions is an expert in bathroom renovations. We can make your bathroom look way better than before, giving it the latest showerheads, vanities and more. Give us a call today.',
    buttonLabel: '',
    imgStart: '',
    img: '/images/homeremodel3.jpeg',
    alt: 'Credit Card'
};
export const homeObjFour= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'COMMERCIAL REMODEL',
    description: 'Does your business need any renovation? here at JB & C Home solutions can help you bring your business back to life.  ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/homeremodel5.jpeg',
    alt: 'Credit Card'
};
export const homeObjFive= {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'Commercial Remodel',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/homeremodel1.jpeg',
    alt: 'Credit Card'
};

export const homeObjSix= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    Headline: 'Areas We Work',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    img: ''
}

