import React from 'react';
import './About.css';
import Fade from 'react-reveal/Fade';


function About() {
    return (
        <>
        <div className="about_hero"></div>
        <div className="about_section">
        <Fade right>
           <h1 className="about_us">ABOUT US</h1>
        </Fade>
        <Fade left>
            <img src="/images/jose.jpg" alt="alt" className="profile" />
        </Fade>
        <Fade bottom>
            <p>
            JB&C Home Solutions is a family owned and operated residential and commercial roofing and construction company based in the heart of Houston. Our main priority is to ensure that all your roofing and construction needs are taken care of with our exceptional service. Other services we provide are gutter removal, home remodeling, interior and exterior painting, fence installation, concrete installation, home maintenance and other. Schedule a call with us for a free estimate!
<br/>
<br/>
<strong>Meet the founder:</strong> <br/>
My passion for roofing and construction started at a really young age. Seeing how hard my dad worked to provide for our family gave me a passion for construction. That’s how it all started! After seeing how pleased my clients were at the end of every service I decided to pursue my long time dream and that’s how JB&C Home Solutions started. We not only do the job right but we make sure to be as time efficient as possible with our fully trained and certified team. We will get the job done right the first time!
            
            
            </p>
        </Fade>
        </div>
        <div className="second_section">
            <div className="grid2">
            <img src="images/roofing1.jpeg" alt="alt" className="sample2"/>
            <h2>JB&C Mission</h2>
            <p>We are commited to excel in customer satisfaction. We accomplish this by providing the best materials 
                and customer service in Houston, Texas. <br></br> Give us a call for a free quote! </p>
            </div>
        </div>
        </>
    )
}

export default About
