import React from 'react';
import GridGallery from '../components/grid-gallery';
import './grid-gallery.css'

function Gallery() {
    const images = [
        "/images/8-5.jpg",
        "/images/8-6.jpg",
        "/images/8-8.jpg",
        "/images/8-16.jpg",
        "/images/8-15.jpg",
        "/images/8-14.jpg",
        "/images/8-21.jpg",
        "/images/8-22.jpg",
        "/images/8-19.jpg",
        "/images/8-18.jpg",
        "/images/8-17.jpg",
        "/images/8-24.jpg",
        "/images/8-25.jpg",
        "/images/8-26.jpg",
        "/images/8-29.jpg",
        "/images/8-28.jpg",
        "/images/8.jpg",
        "/images/8-9.jpg",
        
      
    ]
    return (
        <GridGallery images={images} />
    )
}

export default Gallery
