import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop'
import Footer from './components/pages/Footer/Footer'
import Home from './components/pages/HomePage/Home';
import About from './components/pages/About/About';
import HomeRemodeling from './components/pages/HomeRemodeling/HomeRemodeling'
import Roofing from './components/pages/Roofing/Roofing'
import Gutters from './components/pages/SeamlessGutters/Gutters'
import Concrete from './components/pages/ConcreteWork/Concrete'
import Flooring from './components/pages/TileAndFlooring/Tile'
import Painting from './components/pages/Painting/Painting';
import House from './components/pages/Housekeeping/HouseKeeping'
import Carpentry from './components/pages/Carpentry/Carpentry'
import Fence from './components/pages/FenceWork/Fence'
import Contact from './components/pages/Contact/Contact'
import { Helmet } from "react-helmet";

import MessengerCustomerChat from 'react-messenger-customer-chat';



function App() {
  return (
  <Router>
    <Helmet>
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
      <title>Home Remodel, Roofing & Seamless Gutters Installation | Houston Roofers 832-857-5200</title>
      <meta
      name="description"
      content=" Call us today 832-857-5200. Home Remodeling, Roof Installation in Houston Texas, Residential and Commercial Services.
      "
    />
    <meta 
    name="keywords"
    content="Houston, roofers, roofing, remodeling, painting, fence, Gutter Instalation, Residential, Commercial, repair, 832-857-5200 "
    />
    </Helmet>
  <ScrollToTop/>
  <Navbar />
  <Switch>
    <Route path='/' exact component={Home} />
    <Route path='/homeremodeling' component={HomeRemodeling} />
    <Route path='/about' component={About} />
    <Route path='/roofing' component={Roofing} />
    <Route path='/seamlessgutters' component={Gutters} />
    <Route path='/concretework' component={Concrete} />
    <Route path='/tile&flooring' component={Flooring} />
    <Route path='/painting' component={Painting} />
    <Route path='/housekeeping' component={House} />
    <Route path='/carpentry' component={Carpentry} />
    <Route path='/fencework' component={Fence} />
    {/* <Route path='/contactform' component={Contact} /> */}
  </Switch>
  <MessengerCustomerChat
    pageId="217879423559000"
    appId="1245096389294914"
  />
  <Footer/>
  </Router>
  );
}

export default App;
