import React from 'react';
import './Footer.css';
import {animateScroll as scroll} from 'react-scroll';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {BsFillLightningFill} from 'react-icons/bs';

function Footer() {
  const toggleHome = () => {
    scroll.scrollToTop();
  }
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join our exclusive membership to receive the latest news and trends
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h4>Services</h4>
            <Link to='/homeremodeling'>Home Remodeling</Link>
            <Link to='/roofing'>Roofing</Link>
            <Link to='/seamlessgutters'>Seamless Gutters</Link>
            <Link to='/concretework'>Concrete Work</Link>
            <Link to='/tile&flooring'>Tile/Flooring</Link>
            <Link to='/painting'>Painting</Link>
            <Link to='/housekeeping'>Housekeeping</Link>
            <Link to='/carpentry'>Carpentry</Link>
            <Link to='/fencework'>Fence Work</Link>
          </div>
          <div className='footer-link-items'>
            <h4>Contact Us</h4>
            <Link>email@yahoo.com</Link>
            <a className="phone" href="tel:832-857-5200"> 832-857-5200 </a>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h4>Opening Hours</h4>
            <Link>Monday - Friday : <br></br>  8:00 am - 5:00 pm</Link>
            <Link>Weekends: 8:00 am - 12:00 am (By Appointment) - 832-857-5200</Link>
            <Link>Sunday: Closed</Link>
            <Link>Emergency Service Provided</Link>
            <Link>Credit Cards Accepted</Link>
            <Link>Free On Time Estimates</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
        <div className='footer-logo'>
            <Link to='/' className='social-logo' onClick={toggleHome}>
            <img className="logo1" src="/images/jbclogo.png" alt="logo" />
            </Link>
          </div>

          <div className='footer-logo'>
           <img className="logo2" src="/images/bbb.jpg" alt="logo" />
          </div>
          <small className='website-rights'>davds.design@gmail.com © 2020</small>
          <div className='social-icons'>
            <a
              className='social-icon-link'
              href='https://www.facebook.com/JBC-Home-Solutions-101565334569757'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </a>
            <a
              className='social-icon-link'
              href='https://www.instagram.com/jbc_homesolutions/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;