import Fade from 'react-reveal/Fade';

export default function GridGallery({images}) {
    return (
        <div className="main">
            <Fade left>
            <h2>OUR RECENT WORK</h2>
            </Fade>
        <div className="gallery">
       
            {images && images.map((imageUrl) => <img className="grid" src={imageUrl} alt="alt" />)}
        
        </div>
        </div>
    )
}