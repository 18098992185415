
export const homeObjOne = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: 'OUR SERVICES',
    Headline: 'ROOF INSPECTION IN HOUSTON',
    description: "Have you had your roof inspected recently? if you haven't JB & C Home Solutions is here to help. Roof maintenance or repair is one affordable way of making sure that your home will stay safe. Making sure your roof is secure is incredibly important. Make sure to schedule an appointment with a roof specialist to analyze your roof’s repair needs. ",
    phone:'Give us a call',
    buttonLabel: '',
    imgStart: '',
    img: './images/8-10.jpg',
    alt: '',
    start:'true'
};
export const homeObjThree = {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'RESIDENTIAL ROOFING',
    description: 'With more than 10 years in roofing experience JB & C Home Solutions is available to inspect , repair or replace any type of Residential Roofing your house is in need of. we served all the Houston and surrounding areas including San Antonio. Give us a call for a free quote! ',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/1.jpg',
    alt: 'Credit Card',
    start: 'true'
};
export const homeObjTwo = {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'COMMERCIAL ROOFING',
    description: 'Does your business need Roof Repair or Roof Replacement? JB & C  Home Solutions is the right company for you. We will work with you to give you the best quote of what it will take to get your Roof Repair. ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/roofing2.jpeg',
    alt: 'Credit Card'
};
export const homeObjFour= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    Headline: 'ROOF INSURANCE',
    description: ' Has your Home sustained damage from hail, wind, or other storm damage? Your homeowner insurance should cover most of the costs. Give us a call for more information.',
    buttonLabel: '',
    imgStart: 'start',
    img: '/images/8-4.jpg',
    alt: 'Credit Card'
};
export const homeObjFive= {
    lightBg: false, 
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    Headline: 'METAL ROOFING',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    buttonLabel: '',
    imgStart: '',
    img: '/images/roofing2.jpeg',
    alt: 'Credit Card'
};

export const homeObjSix= {
    lightBg: true, 
    lightText: false,
    lightTextDesc: false,
    Headline: 'Areas We Work',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ',
    img: ''
}

