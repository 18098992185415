export const MenuItems = [
    {
        title: 'HOME REMODELING',
        path: '/homeremodeling',
        cName: 'dropdown-link'
    },
    {
        title: 'ROOFING',
        path: '/roofing',
        cName: 'dropdown-link'
    },
    {
        title: 'SEAMLESS GUTTERS',
        path: '/seamlessgutters',
        cName: 'dropdown-link'
    },
    {
        title: 'CONCRETE WORK',
        path: '/concretework',
        cName: 'dropdown-link'
    },
    {
        title: 'TILE/FLOORING',
        path: '/tile&flooring',
        cName: 'dropdown-link'
    },
    {
        title: 'PAINTING',
        path: '/painting',
        cName: 'dropdown-link'
    },
    {
        title: 'HOUSEKEEPING',
        path: '/housekeeping',
        cName: 'dropdown-link'
    },
    {
        title: 'CARPENTRY',
        path: '/carpentry',
        cName: 'dropdown-link'
    },
    {
        title: 'FENCE WORK',
        path: '/fencework',
        cName: 'dropdown-link'
    }
    
]